import memoji from './memoji_transparent.png';
import logo from './logo.png'
import './App.css';
import {BrowserRouter, HashRouter, Route, Routes} from "react-router-dom";
import HomePage from "./HomePage";
import CaseStudyPage from "./CaseStudyPage";

function App() {
  return (
      <BrowserRouter>
          <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/cstudy" element={<CaseStudyPage />} />
          </Routes>
      </BrowserRouter>
  );
}

export default App;
