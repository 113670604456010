import React from 'react';
import "./CaseStudyPage.css";
import main from './casestudy-images/main.jpg';
import threejsnode from './casestudy-images/3jsnode.png';
import reddit from './casestudy-images/reddit.jpg';
import cameras from './casestudy-images/cameras.png';
import lights from './casestudy-images/lights.png';
import diagram from './casestudy-images/diagram.png';
import multiplayer from './casestudy-images/multiplayer.png';
import stats from './casestudy-images/stats.png';
import bloom from './casestudy-images/bloom.jpg';
import pcmobile from './casestudy-images/pcmobile.jpg';
import readyplayerme from './casestudy-images/readyplayerme.png';
import mixamo from './casestudy-images/mixamo.png';
import baking from './casestudy-images/baking.png';
import characters from './casestudy-images/characters.png';
import rhubarb from './casestudy-images/rhubarb.png';
import oauth from './casestudy-images/oauth.png';
import webrtc from './casestudy-images/webrtc.png';
import collider from './casestudy-images/collider.png';
import socketio from './casestudy-images/socketio.png';
import bbb from './casestudy-images/bbbpuppeteer.png';
import rtc from './casestudy-images/rtc.png';
import polygonreducition from './casestudy-images/polygonreduction.png';
import stack from './casestudy-images/stack.png';

const elements = {
    canvas: "<canvas>",
}

const CaseStudyPage = () => {
    return (
        <div id="container">
            <div id="content">
                <h1>Case Study – Interaktywna platforma 3D do budowania sieci kontaktów w branży przemysłowej</h1>
                <p>Nowy wymiar interakcji przemysłowych, który łączy specjalistów i przedsiębiorstwa w trójwymiarowej sieci kontaktów.</p>
                <div className={"imageContainer"}><video controls playsInline loading="lazy" loop="true" autoPlay="autoplay" muted><source src="rec.mp4" type="video/mp4"/>Your browser does not support the video tag</video><span></span></div>

                <h2>Kontekst</h2>
                <p>Projekt powstał z potrzeby stworzenia innowacyjnej platformy networkingowej dla sektora przemysłowego. Jako jedyny twórca tego projektu, moim celem było umożliwienie przedsiębiorcom interakcji w wirtualnym środowisku, które łączy w sobie zaawansowaną technologię 3D oraz funkcjonalności komunikacyjne.</p>
                <p>Platforma ma na celu nie tylko ułatwienie nawiązywania nowych kontaktów biznesowych, ale także prezentowanie swoich treści i osiągnięć w nowoczesny i angażujący sposób.</p>

                <h2>Technologia</h2>
                <p>Platforma została stworzona przy użyciu nowoczesnych technologii, w tym <b>JavaScript</b> z biblioteką <b>Three.js</b> oraz <b>Node.js</b> wspieranym przez framework <b>Express.js</b>, co zapewnia wyjątkową wydajność i interaktywność.</p>
                <div className={"imageContainer"}><img loading="lazy" src={threejsnode}/><span></span></div>
                <p>Three.js to potężna biblioteka JavaScript, służąca do tworzenia i renderowania grafiki 3D bezpośrednio w <b>przeglądarkach internetowych</b>. Dzięki Three.js możemy łatwo tworzyć skomplikowane i realistyczne sceny 3D, które są fundamentem stworzonej platformy.</p>
                <p>Biblioteka ta umożliwia implementację zaawansowanych efektów wizualnych, takich jak cieniowanie, oświetlenie i animacje, co znacząco wzbogaca doświadczenie użytkownika.</p>
                <div className={"imageContainer"}><img loading="lazy" src={reddit}/><br/><span>Przykład skomplikowanej sceny 3D stworzonej przy użyciu Three.js, pokazujący możliwości tej biblioteki w renderowaniu zaawansowanej grafiki<a href={"https://www.reddit.com/r/threejs/comments/189585e/same_perspective_different_threejs_versions_r147/"}>źródło</a></span></div>
                <p>Platforma została zoptymalizowana pod kątem wydajności, co zapewnia płynne działanie nie tylko na nowoczesnych urządzeniach, ale również na <b>starszych komputerach i telefonach</b>. Dzięki zastosowaniu technologii Three.js oraz technik optymalizacji, omówionych w dalszej części, użytkownicy mogą cieszyć się bogatymi wizualnie doświadczeniami nawet na starszym sprzęcie.</p>
                <p>Aby jednak uzyskać maksymalną płynność i wydajność, zalecane jest korzystanie z urządzeń wyposażonych w dedykowaną kartę graficzną (GPU).</p>
                <div className={"imageContainer"}><img loading="lazy" src={pcmobile}/><span>Prezentacja wyglądu na komputerze oraz urządzeniu mobilnym</span></div>

                <h2>Logika</h2>
                <p>Logika stworzonej platformy 3D została starannie zaprojektowana, aby zapewnić płynne i angażujące doświadczenie użytkownika. Cały proces składa się z kilku kluczowych etapów i komponentów, które współpracują, aby stworzyć dynamiczne i interaktywne środowisko.</p>
                <p>Aby móc wyświetlić cokolwiek za pomocą Three.js, potrzebujemy trzech podstawowych elementów: <b>sceny, kamery i renderera</b>. Te trzy komponenty, współpracując, umożliwiając renderowanie sceny z perspektywy kamery, tworząc bogate, trójwymiarowe doświadczenie dla użytkownika.</p>
                <p>Kluczowe elementy Three.js:</p>
                <ul>
                    <li>Scena (Scene)<p>Scena to kontener, w którym umieszczane są wszystkie obiekty 3D. To tutaj dodajemy modele, światła i inne elementy, które mają być wyświetlane w tworzonym świecie. Scena stanowi <b>element nadrzędny</b> dla większości aktorów w aplikacji, tworząc trójwymiarowe środowisko, które jest renderowane przez silnik Three.js. Dzięki niej możemy efektywnie zarządzać i organizować wszystkie elementy wizualne, zapewniając spójne i atrakcyjne doświadczenie użytkownika</p></li>
                    <li>Kamera (Camera)<p>Kamera działa jak punkt widzenia, z którego obserwujemy scenę. W stworzonym projekcie użyto <b>kamery perspektywicznej (PerspectiveCamera)</b>, która imituje sposób, w jaki ludzkie oko postrzega świat, zapewniając bardziej realistyczne wrażenia. Kamera definiuje pozycję i orientację punktu widzenia w wirtualnym świecie</p><div className={"imageContainer"}><img loading="lazy" src={cameras}/><span>Kamera perspektywiczna i kamera ortograficzna – porównanie<a href={"https://medium.com/@gopisaikrishna.vuta/exploring-cameras-in-three-js-32e268a6bebd"}>źródło</a></span></div></li>
                    <li>Renderer <p>Renderer to komponent odpowiedzialny za rysowanie sceny na ekranie. W Three.js renderer korzysta z elementu <span className={"code"}>{elements.canvas}</span> do rysowania obiektów 3D. Renderer przekształca obiekty 3D w obraz 2D, wyświetlany na ekranie, z punktu widzenia kamery</p></li>
                    <li>Światło (Light)<p>Światła są kluczowe dla uzyskania realistycznych efektów oświetlenia w wirtualnym świecie. Bez odpowiedniego oświetlenia obiekty 3D byłyby niewidoczne. W Three.js istnieje wiele rodzajów świateł, które można wykorzystać, takich jak <b>światła kierunkowe, punktowe i ambientowe</b>, aby stworzyć realistyczne i dynamiczne sceny</p><div className={"imageContainer"}><img loading="lazy" src={lights}/><span>Typy świateł w Three.js<a href={"https://medium.com/@althafkhanbecse/title-enlightening-3d-worlds-mastering-lighting-techniques-in-three-js-c860caa8cdcf"}>źródło</a></span></div></li>
                    <li>Siatka (Mesh)
                        <p>
                            Siatka (Mesh) to 3D obiekt, który jest używany w scenie Three.js. Składa się z dwóch głównych komponentów:
                            <ul>
                                <li>Geometria (Geometry): Określa kształt obiektu 3D, definiując jego wierzchołki, krawędzie i powierzchnie</li>
                                <li>Materiał (Material): Określa wizualny aspekt obiektu, takie jak kolor, tekstura, połysk i przezroczystość. Materiały mogą również definiować, jak światło oddziałuje z powierzchnią obiektu</li>
                            </ul>
                        </p>
                    </li>
                </ul>
                <div className={"imageContainer"}><img loading="lazy" src={diagram}/><span>Diagram hierarchii opisywanych elementów</span></div>
                <p>Proces inicjalizacja świata 3D:</p>
                <ul>
                    <li>Inicjacja<p>Inicjację naszego świata 3D rozpoczynamy od pliku <span className={"code"}>index.js</span>, w którym tworzymy główny obiekt klasy <span className={"code"}>Experience</span>, przekazując do niego element <span className={"code"}>{elements.canvas}</span>. W tym pliku również łączymy się z serwerem wykorzustując Socket.io, ładujemy funkcje narzędziowe (utility functions) oraz integrujemy interfejsy użytkownika. <span className={"code"}>index.js</span> stanowi fundament, na którym opiera się cała platforma, zapewniając płynne i zintegrowane działanie wszystkich komponentów</p></li>
                    <li>Ładowanie zasobów <p>Po skonfigurowaniu podstawowych elementów Three.js i wstępnej konfiguracji w pliku <span className={"code"}>index.js</span>, następuje inicjalizacja zasobów, takich jak modele w formacie .glb i tekstury. Modele 3D są ładowane za pomocą loaderów Three.js, które odczytują pliki .glb i przygotowują je do renderowania</p></li>
                    <li>Ładowanie świata i obsługa kolizji<p>Następnie ładowany jest świat i jego elementy a obsługa kolizji jest realizowana za pomocą struktury Octree. <b>Octree</b> to zaawansowana, hierarchiczna struktura danych, która dzieli przestrzeń na mniejsze sekcje, co umożliwia efektywne zarządzanie kolizjami.
                        Wszystkie ciała graczy są modelowane jako sfery, co upraszcza testy kolizji.
                        Pozostałe obiekty, takie jak krzesła, ściany czy rośliny, są modelowane jako proste bryły, takie jak sześciany.
                        Dzięki zastosowaniu Octree możemy <b>szybko i skutecznie wykrywać kolizje między obiektami</b>, co jest kluczowe dla płynności rozgrywki oraz ogólnej wydajności systemu</p></li>
                        <div className={"imageContainer"}><img loading="lazy" src={collider}/><span>Uproszczone modele obiektów w programie Blender</span></div><br/>
                    <li>Inicjalizacja klasy gracza<p>Każdy gracz jest reprezentowany przez klasę, która zarządza jego stanem i interakcjami w świecie. Klasa gracza zawiera informacje o pozycji, ruchu, animacjach i innych atrybutach, które są niezbędne do prawidłowego działania postaci w wirtualnym świecie</p></li>
                    <li>Przypisywanie tekstur i dodawanie modeli do sceny<p>Modele mapy i inne obiekty są następnie teksturowane, czyli przypisywane są do nich odpowiednie tekstury, które nadają im realistyczny wygląd. Po teksturowaniu, modele są dodawane do sceny, gdzie stają się częścią interaktywnego środowiska</p></li>
                    <li>Inicjalizacja środowiska<p>Ostatnim krokiem jest inicjalizacja środowiska świata, która obejmuje dodanie świateł i <b>mapy środowiskowej (envMap)</b>. Światła są kluczowe dla uzyskania realistycznych efektów oświetlenia, a envMap zapewnia odbicia i ogólny nastrój sceny</p></li>
                </ul>

                <h2>Multiplayer</h2>
                <p>Multiplayer w stworzonej platformie jest realizowany za pomocą biblioteki <b>Socket.io</b>. Technologia ta umożliwia bezproblemową komunikację w czasie rzeczywistym między serwerem a klientami.</p>
                <div className={"imageContainer"}><img loading="lazy" src={socketio}/><span></span></div>
                <p>Proces działania:</p>
                <ul>
                    <li>Emisja danych przez klienta<p>Połączony ze socketem klient emituje do serwera kluczowe informacje, takie jak pozycja postaci, aktualna animacja, pseudonim i inne istotne dane. Te informacje są <b>wysyłane w regularnych odstępach czasu</b>, zapewniając płynność i aktualność stanu rozgrywki</p></li>
                    <li>Nasłuchiwanie przez serwer<p>Serwer nasłuchuje tych eventów od wszystkich podłączonych użytkowników. Każdy otrzymany event jest analizowany i przetwarzany, aby zaktualizować globalny stan rozgrywki</p></li>
                    <li>Rozsyłanie danych do użytkowników<p>Po przetworzeniu danych, serwer łączy je i rozsyła broadcastem do wszystkich podłączonych użytkowników. Dzięki temu każdy klient jest na bieżąco informowany o zmianach w stanie rozgrywki, co umożliwia <b>synchronizację wszystkich uczestników w czasie rzeczywistym</b></p></li>
                    <li>Odebranie danych przez użytkownika<p>Klient odbiera dane przesłane przez serwer i aktualizuje stan rozgrywki lokalnie. Może to obejmować dodawanie nowych postaci do sceny, przemieszczanie postaci w przestrzeni 3D czy uruchomienie odpowiednich animacji</p></li>
                </ul>
                <div className={"imageContainer"}><img loading="lazy" src={multiplayer}/><span>Schemat działania Socket.io, pokazujący jak klient i serwer wymieniają się informacjami, aby utrzymać synchronizację multiplayer w czasie rzeczywistym</span></div>

                <h2>Postacie</h2>
                <p>Postacie 3D odgrywają kluczową rolę w wirtualnej platformie, wnosząc dodatkową interaktywność do interaktywnego świata.</p>
                <p>Modele postaci zostały utworzone z pomocą strony <b>ReadyPlayer.Me</b>, która oferuje szerokie możliwości personalizacji i umożliwia tworzenie unikalnych awatarów. Każdy awatar został starannie zaprojektowany, aby zapewnić różnorodność i indywidualność.</p>
                <div className={"imageContainer"}><video controls playsInline loading="lazy" loop="true" autoPlay="autoplay" muted><source src="rec1.mp4" type="video/mp4"/>Your browser does not support the video tag</video><span><br/>Przykład personalizowania awatarów dostępnych na platformie ReadyPlayer.Me, pokazujący różnorodność i możliwości dostosowania wyglądu postaci</span></div>
                <p>Animacje stworzonych postaci pochodzą z <b>Mixamo od Adobe</b>, serwisu oferującego ogromną kolekcję gotowych animacji. Mixamo umożliwia szybkie i łatwe przypisywanie modelom 3D postaci różnorodnych ruchów, takich jak chód, bieg, czy nawet taniec, co znacząco wzmacnia realizm i dynamikę interakcji.</p>
                <div className={"imageContainer"}><video controls playsInline loading="lazy" loop="true" autoPlay="autoplay" muted><source src="rec2.mp4" type="video/mp4"/>Your browser does not support the video tag</video><span><br/>Przykład różnych animacji dostępnych na platformie Mixamo, pokazujący różnorodność i naturalność ruchów postaci</span></div>
                <p>Dzięki połączeniu zaawansowanej personalizacji z ReadyPlayer.Me oraz dynamicznych animacji z Mixamo, stworzone postacie 3D oferują wyjątkowe i angażujące doświadczenie dla użytkowników, umożliwiając im pełne zanurzenie się w wirtualnym świecie.</p>

                <h2>Optymalizacje</h2>
                <p>Aby zapewnić płynność działania i wysoką jakość wizualną platformy, zastosowano szereg zaawansowanych technik optymalizacji opisanych poniżej.</p>
                <ul>
                    <li>Zrozumienie obciążeń<p>Na wydajność naszej platformy wpływają różne czynniki, takie jak: rozmiar tekstur, liczba animacji, złożoność modeli, liczba obiektów 3D, ilość funkcji, oraz światła i cienie. Każdy z tych elementów może znacznie obciążać system, dlatego ich <b>optymalizacja jest kluczowa</b></p></li>
                    <li>Monitorowanie wydajości<p>Do śledzenia wydajności użyto <b>stats.js</b>, czyli monitora wydajności JavaScript. Ten prosty panel informacyjny pomaga monitorować wydajność kodu, dostarczając kluczowych danych<div className={"imageContainer"}><img loading="lazy" src={stats}/><span>Przykładowe wartości wskazywane przez monitor stats.js</span></div></p></li>
                    <li>Technika Bakingu<p>Jedną z kluczowych metod optymalizacji jest <b>technika bakingu</b>, która pozwala uzyskać wysoką wydajność przy zachowaniu atrakcyjnego wyglądu sceny.</p><p>Baking polega na przetwarzaniu i <b>zapisywaniu efektów oświetlenia i cieni w teksturach</b> obiektów 3D. Zamiast obciążać procesor generowaniem dynamicznych efektów oświetlenia w czasie rzeczywistym, tekstury zawierające te efekty są nakładane na modele. Znacznie zmniejsza to obciążenie procesora podczas renderowania, co przekłada się na lepszą wydajność i płynność działania sceny 3D.</p><div className={"imageContainer"}><img loading="lazy" src={baking}/><span>Przykład zastosowania techniki bakingu z wykorzystaniem programu Blender, pokazujący, jak efekty oświetlenia i cieni są zapisane w teksturach obiektów, co zmniejsza obciążenie procesora</span></div></li><br/>
                    <li>Optymalizacja tekstur<p>Do optymalizacji tekstur użyto narzędzia <b>Squoosh.app</b>, które pozwala na niesamowicie szybkie i łatwe kompresowanie zdjęć wraz z podglądem na żywo. Kompresja tekstur zmniejsza ich rozmiar, co przyspiesza ładowanie i renderowanie scen</p></li>
                    <li>Optymalizacja geometrii<p>Elementy 3D sceny zostały starannie zoptymalizowane, poprzez <b>redukcję liczby krawędzi i wierzchołków</b>. To zmniejszenie złożoności geometrii znacząco podnosi wydajność renderowania obiektów 3D, co bezpośrednio przekłada się na szybsze ładowanie scen oraz większą ilość klatek na sekundę, zapewniając płynniejsze i bardziej dynamiczne wrażenia wizualne</p><div className={"imageContainer"}><img loading="lazy" src={polygonreducition}/><span><br/>Przykład redukcji liczby polygonów modelu 3D<a href={"https://blender.stackexchange.com/questions/255234/gradual-polygon-reduction"}>źródło</a><br/></span></div></li>
                    <li>Grupowanie modeli obiektów (merging)<p>Dodatkowo, modele obiektów 3D zostały pogrupowane w taki sposób, aby ograniczyć liczbę tzw. <b>draw calls</b>. Draw calls to procesy, w których procesor graficzny (GPU) renderuje każdy obiekt na scenie. Zmniejszenie ich liczby jest kluczowe dla wydajności, ponieważ każde dodatkowe wywołanie zwiększa obciążenie GPU. Poprzez grupowanie obiektów, zmniejszamy liczbę draw calls, co pozwala na bardziej efektywne wykorzystanie zasobów i przyspiesza renderowanie całej sceny 3D</p></li>
{/*
                    <li>Merging modeli obiektów<p>Merging obiektów polega na łączeniu wielu mniejszych obiektów w jeden większy. Dzięki temu zmniejsza się liczba draw calls, ponieważ zamiast renderować wiele oddzielnych obiektów, GPU renderuje jeden złożony obiekt. Proces ten polega na scaleniu geometrii i materiałów, co poprawia wydajność renderowania.</p></li>
*/}
                    <li>Optymalizacja funkcji<p>Dodatkowo, wyciągnięcie niektórych funkcji poza główną funkcję renderującą pozwala na ich wolniejsze wykonywanie. Funkcje te nie muszą być aktualizowane w każdej klatce, co zmniejsza obciążenie systemu bez wpływu na ogólne wrażenie prędkości działania platformy. Przykłady takich funkcji obejmują operacje, które nie wymagają natychmiastowej aktualizacji, ale nadal muszą być wykonywane regularnie</p></li>
                </ul>
                <p>Dzięki tym technikom optymalizacji utworzona platforma może dostarczać bogate wizualnie i interaktywne środowiska, które działają płynnie na szerokim zakresie urządzeń, od nowoczesnych komputerów po starsze modele i urządzenia mobilne. To podejście zapewnia, że każdy użytkownik może cieszyć się doskonałym doświadczeniem bez kompromisów w jakości grafiki czy wydajności.</p>

                <h2>Uwierzytelnienie LinkedIn</h2>
                <p>W celu zapewnienia wygodnego logowania użytkowników, tworzona platforma została zintegrowana z <b>LinkedIn Authentication</b>, wykorzystując <b>3-legged OAuth</b>.</p>
                <p>Dzięki tej integracji możemy pobrać imię i nazwisko gracza bez konieczności wpisywania pseudonimu, co zwiększa autentyczność i ułatwia nawiązywanie kontaktów. Ponadto, możemy pobrać zdjęcie profilowe użytkownika, które jest wyświetlane nad głową postaci w wirtualnym świecie. Dzięki temu, postacie stają się bardziej rozpoznawalne, co zwiększa realizm i ułatwia interakcje między użytkownikami.</p>
                <p>Implementacja i proces autoryzacji:</p>
                <ul>
                    <li>Skonfigurowanie aplikacji na portalu deweloperskim LinkedIn, aby uzyskać Client ID i Client Secret</li>
                    <li>Implementacja przycisku logowania LinkedIn na stronie platformy i przekierowanie użytkownika po kliknięciu na stronę autoryzacji LinkedIn, gdzie się uwierzytelnia</li>
                    <li>Przekazanie kodu autoryzacyjnego przez serwer LinkedIn do aplikacji po pomyślnej autoryzacji</li>
                    <li>Wysłanie przez aplikację Client ID, Client Secret oraz otrzymanego kodu do LinkedIn, który zwraca token dostępu</li>
                    <li>Użycie przez aplikację tokenu dostępu do wykonywania wywołań API w imieniu użytkownika</li>
                </ul>
                <div className={"imageContainer"}><img loading="lazy" src={oauth}/><span><br/>Diagram procesu autoryzacji<a href={"https://learn.microsoft.com/en-us/linkedin/shared/authentication/authorization-code-flow?tabs=HTTPS1"}>źródło</a></span></div>

                <h2>Czat tekstowy i głosowy</h2>
                <p>Komunikacja jest kluczowym elementem stworzonej platformy, dlatego zaimplementowano funkcjonalności czatu tekstowego oraz czatu głosowego, które zapewniają płynne i efektywne interakcje między użytkownikami.</p>
                <p>Chat tekstowy jest oparty na technologii <b>Socket.io</b>, która umożliwia błyskawiczną wymianę wiadomości w czasie rzeczywistym. Historia rozmów jest tymczasowo zapisywana na serwerze, co pozwala na synchronizację rozmów pomiędzy wszystkimi użytkownikami. </p>
                <p>Czat głosowy natomiast wykorzystuje technologię <b>WebRTC (Web Real Time Communication)</b>, która umożliwia bezpośrednią komunikację głosową między użytkownikami w czasie rzeczywistym. WebRTC zapewnia wysoką jakość dźwięku i minimalne opóźnienia, co sprawia, że rozmowy są płynne i naturalne.</p>
                <div className={"imageContainer"}><img loading="lazy" src={rtc}/><span></span></div>
                <p>WebRTC to projekt open source, który umożliwia komunikację <b>peer-to-peer</b> między przeglądarkami bez potrzeby instalowania dodatkowych wtyczek. Bezpośrednia komunikacja między przeglądarkami poprawia wydajność i redukuje opóźnienia, ponieważ klienci nie muszą ciągle wysyłać i odbierać wiadomości przez serwer. Serwer jest potrzebny tylko do ustanowienia połączeń, co nazywa się <b>procesem sygnalizacji</b>.</p>
                <p>Proces łączenia klientów:</p>
                <ul>
                    <li>Klient inicjuje połączenie</li>
                    <li>Tworzy ofertę za pomocą SDP (Session Description Protocol) i wysyła ją do drugiego klienta</li>
                    <li>Drugi klient odpowiada na ofertę wiadomością z opisem SDP</li>
                    <li>Rozmówcy ustalają możliwości multimedialne połączenia (kodeki, metadane), lecze jeszcze nie mogą się połączyć z powodu braku informacji o NAT, adresach IP i portach. To właśnie rozwiązuje protokół ICE</li>
                </ul>
                <p><b>ICE</b> jest metodą komunikacji peer-to-peer, która umożliwia przesyłanie i odbieranie informacji medialnych przez Internet. ICE zbiera dostępne połączenia sieciowe, znane jako kandydaci ICE, i używa protokołów STUN (Session Traversal Utilities for NAT) oraz TURN (Traversal Using Relays around NAT) do pokonywania NAT i firewalli. ICE próbuje połączyć klientów bezpośrednio przez UDP lub TCP. Jeśli to się nie uda z powodu NAT lub firewalla, <b>używa serwera STUN</b> do znalezienia publicznego adresu i portu peerów.</p>
                <br/><div className={"imageContainer"}><img loading="lazy" src={webrtc}/><span><br/>Diagram prezentujący proces sygnalizacji oraz wymianę danych między przeglądarkami klientów<a href={"https://acidtango.com/thelemoncrunch/how-to-implement-a-video-conference-with-webrtc-and-node/"}>źródło</a></span></div>

                <h2>Integracja z systemem konferencyjnym i scraping konferencji</h2>
                <p>Aby zapewnić kompleksowe narzędzia do organizacji i uczestnictwa w konferencjach, tworzona platforma została zintegrowana z zaawansowanym systemem konferencyjnym <b>BigBlueButton (BBB)</b>. Wykorzystano również <b>Puppeteer</b>, bibliotekę dla Node.js, która umożliwia automatyzację przeglądarek.</p>
                <div className={"imageContainer"}><img loading="lazy" src={bbb}/><span></span></div>
                <p>W celu zapewnienia wysokiej jakości transmisji na żywo, Puppeteer działa na wirtualnej maszynie, streamując konferencje przez protokół <b>RTMP</b>. Automatyzacja konferencji przy użyciu Puppeteera pozwala na bezproblemowe przechwytywanie i przesyłanie strumieni wideo. Stream jest następnie odbierany i wyświetlany w wirtualnym świecie na małym ekranie, umożliwiając uczestnikom oglądanie konferencji na żywo również w środowisku 3D.</p>
                <p>Dzięki tym technologiom, tworzona platforma oferuje niezrównane możliwości w zakresie organizacji i uczestnictwa w konferencjach.</p>

                <h2>Realistyczne Boty Postaci</h2>
                <p>Utworzone boty postaci są nie tylko narzędziami do interaktywnej pomocy, ale także pełnoprawnymi uczestnikami wirtualnego świata, zapewniając wsparcie i angażujące doświadczenia. Dzięki zaawansowanym algorytmom przetwarzania języka naturalnego, <b>boty prowadzą konwersacje</b> z użytkownikami, oferując interaktywną pomoc i wsparcie w czasie rzeczywistym.</p>
                <p>Aby zwiększyć realizm interakcji, boty zostały <b>wzbogacone o animacje</b> ruchu ust i mrugnięcia oczu. Ruchy ust są synchronizowane z wypowiadanymi słowami, co sprawia, że konwersacje z botami są bardziej autentyczne. Mrugnięcia oczu dodają kolejny poziom realizmu.</p>
                <p>Do animacji ruchu ust wykorzystano narzędzie <b>Rhubarb Lip Sync</b>, które generuje plik JSON z pozycjami ust określonymi w czasie nagrania głosowego. Te dane są następnie wykorzystywane do animowania pozycji ust po stronie klienta za pomocą Three.js.</p>
                <p>Rhubarb Lip Sync obsługuje od sześciu do dziewięciu różnych pozycji ust, co pozwala na dokładne odwzorowanie ruchów warg podczas mówienia. Dzięki tym technologiom boty postaci oferują użytkownikom realistyczne i angażujące doświadczenia.</p>
                <div className={"imageContainer"}><img loading="lazy" src={rhubarb}/><span><br/>Podstawowe sześć kształtów ust opracowane przez studio Hanna-Barbera dla takich programów jak Scooby-Doo czy Flintstonowie, które stało się standardem w animacji</span></div>

                <h2>Wykorzystanie AI</h2>
                <p>Stworzone boty postaci, oparte o technologię AI, oferują naturalne i dynamiczne konwersacje, które wzbogacają interakcje z użytkownikami. Cały proces jest złożony i wieloetapowy, został starannie zaprojektowany, aby zapewnić niezwykle <b>płynne i autentyczne</b> doświadczenia interakcyjne.</p>
                <div className={"imageContainer"}><img loading="lazy" src={stack}/><span>Wykorzystane technologie</span></div>
                <p>Proces generowania odpowiedzi tekstowej obejmuje:</p>
                <ul>
                    <li>Konwersję zapytania tekstowego użytkownika na wektor za pomocą techniki embeddings</li>
                    <li>Wyszukiwanie w bazie danych Qdrant za pomocą wygenerowanego wektora zapytania</li>
                    <li>Sortowanie wstępnych wyników wyszukiwania według ich trafności</li>
                    <li>Użycie modelu do ponownego ocenienia wyników na podstawie dodatkowych kryteriów</li>
                    <li>Tworzenie instrukcji dla AI na podstawie wyników wyszukiwania wraz ze wskazówkami</li>
                    <li>Konstruowanie formatu dialogowego, który naprzemiennie uwzględnia wejścia systemu i użytkownika</li>
                    <li>Połączenie przygotowanego template z LLM, tworząc łańcuch przetwarzania</li>
                    <li>Wywołanie modelu z zapytaniem użytkownika jako danymi wejściowymi, co pozwala modelowi przetwarzać dane wejściowe i generować odpowiedź na podstawie nauczonych wzorców i danych</li>
                    <li>Uzyskanie treści odpowiedzi z przetworzonego wyniku</li>
                    <li>Odesłanie treści odpowiedzi tekstowej z powrotem do klienta</li>
                </ul>
                <p>Proces generowania odpowiedzi bota obejmuje:</p>
                <p>Podział odpowiedzi AI na równe części, co zapewnia, że synteza mowy jest zarządzana efektywnie i może być przetwarzana efektywnie. Następnie dla każdej części tekstu wykonywane są następujące kroki.</p>
                <ul>
                    <li>Wywołanie API OpenAI w celu generowania mowy z danej części tekstu</li>
                    <li>Zapisanie wygenerowanej mowy do pliku .opus co pozwala na minimalizację rozmiaru pliku</li>
                    <li>Konwertowanie pliku w formacie .opus na format .ogg przy użyciu narzędzia ffmpeg</li>
                    <li>Generowanie danych synchronizacji ust do audio potrzebnych do animacji</li>
                    <li>Przygotowanie danych audio i synchronizacji ust do odesłania do klienta</li>
                    <li>Wysyłanie przygotowanych danych do klienta przez socket, zapewniając komunikację w czasie rzeczywistym</li>
                </ul>
                <p>Każda część tekstu jest <b>przetwarzana sekwencyjnie</b>, co zapewnia, że każdy etap jest zakończony przed rozpoczęciem następnego. Metoda ta pomaga zarządzać wykorzystaniem zasobów i zapewnia, że pliki audio są prawidłowo zsynchronizowane z odpowiadającymi im częściami tekstu.</p>
                <p>Dzięki implementacji tych zaawansowanych technologii boty mogą prowadzić realistyczne i interaktywne konwersacje, oferując użytkownikom wsparcie najwyższej jakości oraz angażujące doświadczenia w wirtualnym świecie utworzonej platformy.</p>

                <h2>Post processing</h2>
                <p>Post-processing w renderowaniu scen w Three.js może znacząco poprawić ostateczny efekt wizualny, dodając takie efekty jak głębia ostrości, rozmycie ruchu i bloom. Ważne jest jednak, aby rozważyć korzyści wobec kosztów wydajności, ponieważ <b>post-processing może znacznie obciążyć system</b>.</p>
                <p>Dodanie post-processingu podwaja liczbę draw calls, co może być problematyczne dla urządzeń o niższej wydajności. Dlatego generalnie zaleca się unikanie post-processingu, chyba że jest on absolutnie niezbędny dla projektu. Jeśli jednak mamy pewien zapas wydajności, dodanie efektu bloom może znacząco poprawić wygląd sceny.</p>
                <p>W utworzonym projekcie użyto tylko <b>efektu bloom</b>, aby uzyskać lepszy wygląd sceny bez nadmiernego obciążania systemu. Bloom dodaje atrakcyjną poświatę wokół jasnych obiektów, co poprawia estetykę i wizualny urok sceny.</p>
                <div className={"imageContainer"}><img loading="lazy" src={bloom}/><span><br/>Przykład zastosowania efektu bloom w Three.js, pokazujący atrakcyjną poświatę wokół jasnych obiektów<a href={"https://www.youtube.com/watch?v=dc5iJVInpPY"}>źródło</a></span></div>

                <h2>Podsumowanie</h2>
                <p>Dzięki utworzonej platformie 3D, sektor przemysłowy zyskał narzędzie umożliwiające efektywną komunikację i networking w wirtualnej przestrzeni. Integracja zaawansowanych technologii AI oraz technik renderowania sprawiła, że platforma ta jest nie tylko innowacyjna, ale także niezwykle wydajna.</p>
                <div className={"imageContainer"}><img loading="lazy" src={characters}/><span></span></div>
            </div>
            <div id="footer">
                <p style={{fontSize: "14px"}}>All rights reserved.</p>
            </div>
        </div>
    );
}

export default CaseStudyPage;
