import memoji from './memoji_transparent.png';
import logo from './logo.png'
import './App.css';

function HomePage() {
    return (
        <div className="App">
            <header className="header">
                <img src={logo}/>
                <p>pprzybylo.pl</p>
            </header>
            <div className="content">
                <span class="name">Patryk</span>
                <span class="lastname">Przybyło</span>
                <div className="links">
                    <div className="single_link">
                        <a href="https://github.com/p-t-k1">
                            <i className="fab fa-github"/>
                            <p>GitHub</p>
                        </a>
                    </div>
                    <div className="single_link">
                        <a href="https://www.linkedin.com/in/patryk-przyby%C5%82o-b57626237/">
                            <i className="fab fa-linkedin"/>
                            <p>LinkedIn</p>
                        </a>
                    </div>
                    <div className="single_link">
                        <a href="mailto:hello@pprzybylo.pl">
                            <i className="far fa-envelope"/>
                            <p>hello@pprzybylo.pl</p>
                        </a>
                    </div>
                </div>
            </div>
            <footer className="footer">
                <div className="wave-wrapper">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#1d2935" fill-opacity="1" d="M0,128L80,149.3C160,171,320,213,480,202.7C640,192,800,128,960,106.7C1120,85,1280,107,1360,117.3L1440,128L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path></svg>
                </div>
                <img className={"memoji"} src={memoji}/>
                <p>© 2024 Patryk Przybyło - All rights reserved.</p>
            </footer>
        </div>
    );
}

export default HomePage;
